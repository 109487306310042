<template>
<div class="container">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    Search
                </div>

                <div class="card-body">
                    <div class="input-group">
                        <input class="form-control" v-model="searchTerm" autocomplete="on" placeholder="(amazon or ebay) and -customer" :disabled="isSearching" @keyup.enter="search()">
                        <div class="input-group-append" color="primary">
                            <CButton @click="search()" color="primary" :disabled="isSearching || searchTerm.length === 0" style="width: 80px">
                                <div v-if="isSearching" class="spinner-border spinner-border-sm text-light" role="status" style="">
                                    <span class="sr-only"></span>
                                </div>
                                <div v-else>
                                    Search
                                </div>
                            </CButton>
                        </div>
                    </div>

                    <div v-if="errorMessage">
                        <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
                    </div>
                    <div v-else-if="response && response.results && response.results.length === 0">
                        <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">No results found.</div>
                    </div>
                    <div v-else-if="response && response.results" style="margin: 15px 0px -20px 0px">
                        <div class="card-box" style="min-height: 50px; width: 100%; margin-top: 15px; margin-bottom: 5px">
                            <table width="100%">
                                <tr>
                                    <td>
                                        <div style="font-size: 14px; font-weight: 600">Results <span v-if="response.results.length > 0"> ({{ response.found.toLocaleString() }})
                                                <span style="cursor: pointer" @click="clickedShareURL()">
                                                    <CIcon name="cil-share-alt" />
                                                </span>
                                            </span></div>
                                    </td>
                                    <td align="right" style="padding-right: 15px; font-size: 13px">
                                        <span v-if="sort != 'last_seen'" class="interval-link" @click="clickedSort('last_seen')">Last Seen</span>
                                        <span v-if="sort == 'last_seen'" style="font-weight: bold">Last Seen</span>
                                        |
                                        <span v-if="sort != 'frequency'" class="interval-link" @click="clickedSort('frequency')">Times Seen</span>
                                        <span v-if="sort == 'frequency'" style="font-weight: bold">Times Seen</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    </td>
                                    <td align="right" style="padding-right: 15px; font-size: 13px">
                                        <span v-if="recordType !== 'all'" class="interval-link" @click="clickedRecordType('all')">All</span>
                                        <span v-if="recordType === 'all'" style="font-weight: bold">All</span>
                                        |
                                        <span v-if="recordType !== 'sms'" class="interval-link" @click="clickedRecordType('sms')">SMS</span>
                                        <span v-if="recordType === 'sms'" style="font-weight: bold">SMS</span>
                                        |
                                        <span v-if="recordType !== 'call-transcript'" class="interval-link" @click="clickedRecordType('call-transcript')">Call Transcript</span>
                                        <span v-if="recordType === 'call-transcript'" style="font-weight: bold">Call Transcript</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    </td>
                                    <td align="right" style="padding-right: 15px; font-size: 13px">
                                        <span style="font-weight: bold">Profiles: </span>
                                        <span v-if="!profilesOn" class="interval-link" @click="clickedProfile(true)">On</span>
                                        <span v-else style="font-weight: bold">On</span>
                                        |
                                        <span v-if="profilesOn" class="interval-link" @click="clickedProfile(false)">Off</span>
                                        <span v-else style="font-weight: bold">Off</span>
                                    </td>
                                </tr>
                            </table>
                            <hr style="margin-top: 5px">
                            <div v-if="isSearching" style="text-align: center">
                                <span class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 5px">
                                    <span class="sr-only"></span>
                                </span>
                                <span style="padding-left: 7px"> Loading </span>
                            </div>
                            <div v-else style="text-align: center">
                                <CCard v-for="result in response.results">
                                    <CCardBody :style="getBlockedColorState(result.id)">
                                        <CCardSubtitle class="mb-2 text-muted">
                                            <span style="float: left">
                                                <CBadge color="primary" v-if="result.record_type === 'sms'"> SMS </CBadge>
                                                <CBadge color="primary" v-else>Call Transcript</CBadge>

                                                <span style="margin-left: 2px">
                                                    <CBadge stfle="cursor: pointer" color="primary" v-if="response && response.profiles[result.id] && response.profiles[result.id].sender.length > 0" @click="clickedSender(response.profiles[result.id].sender)">Sender: {{response.profiles[result.id].sender}}</CBadge>
                                                </span>

                                                <span v-if="sort == 'last_seen'">
                                                    last seen <b>
                                                        <timeago :datetime="result.last_seen"></timeago>
                                                    </b>, seen {{ result.frequency.toLocaleString() }} times
                                                </span>
                                                <span v-else>
                                                    last seen <timeago :datetime="result.last_seen"></timeago>, seen <b>{{ result.frequency.toLocaleString() }} times </b>
                                                </span>
                                            </span>
                                            <span v-if="response && response.profiles[result.id]" style="float: right;">
                                                <span style="cursor: pointer" @click="clickedRecord(result)">
                                                    <CIcon name="cilExternalLink" />
                                                </span>
                                            </span>
                                        </CCardSubtitle>
                                        </br>
                                        <div style="text-align-last: left">
                                            {{ result.body }}
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
export default {
    name: 'Search',
    data() {
        return {
            errorMessage: null,
            response: null,
            sort: "last_seen",
            recordType: 'all',
            profilesOn: true,
            isSearching: false,
            searchTerm: "",

        }
    },
    methods: {
        clickedShareURL() {
            var routeData = this.$router.resolve({ path: `/keyword-search/search` });
            let url = `https://admin.robocalls.ai/${routeData.href}?s=${encodeURIComponent(this.searchTerm)}&order_by=${encodeURIComponent(this.sort)}&type=${encodeURIComponent(this.recordType)}&include_profiles=${this.profilesOn}`
            navigator.clipboard.writeText(url);
        },
        getBlockedColorState(id) {
            if (!this.response.profiles || !this.response.profiles[id]) {
                return 'background-color: #ebedef'
            }

            if (this.response.profiles[id].blocked) {
                return 'background-color: #fadddd'
            }

            return 'background-color: #d5f1de'
        },
        clickedSort(sort) {
            if (this.isSearching) {
                return;
            }
            this.sort = sort;
            this.search();
        },
        clickedRecordType(recordType) {
            if (this.isSearching) {
                return;
            }
            this.recordType = recordType;
            this.search();
        },
        clickedProfile(enabled) {
            if (this.isSearching) {
                return;
            }
            this.profilesOn = enabled;
            this.search();
        },
        clickedRecord(record) {
            if (!record) {
                return;
            }

            if (record.record_type === "sms") {
                let routeData = this.$router.resolve({
                    path: `/sms-profile/fingerprint/${encodeURIComponent(record.id)}`
                });
                window.open(routeData.href, '_blank');
            } else {
                window.open(`https://platform.robocalls.ai/fingerprint-profile?fingerprint=${encodeURIComponent(record.id)}`, '_blank');
            }
        },
        clickedSender(sender) {
            let routeData = this.$router.resolve({
                path: `/sms-profile/sender/${encodeURIComponent(sender)}`
            });
            window.open(routeData.href, '_blank');
        },
        //--------------------------------------------------------------------------
        parseMessage(body) {
            try {
                var result = JSON.parse(body);
                return result.results;
            } catch (err) {
                return "";
            }
        },
        //--------------------------------------------------------------------------
        processResponse(response) {
            if (!response || response.found === 0) {
                this.errorMessage = "Server did not return search results";
                return;
            }
            this.response = response;
        },
        //--------------------------------------------------------------------------
        search() {

            // Make sure we have a sender
            if (!this.searchTerm) {
                return;
            }

            // Make sure we're not currently searching
            if (this.isSearching) {
                return;
            } else {
                this.isSearching = true;
                this.errorMessage = null;
            }

            var that = this;
            var method = "GET";
            let url = `https://keyword-search.platform.robocalls.ai/search?s=${encodeURIComponent(this.searchTerm)}&order_by=${encodeURIComponent(this.sort)}&type=${encodeURIComponent(this.recordType)}&include_profiles=${this.profilesOn}`
            var requiresSession = true;
            RobocallsAI.shared().execute(method, url, null, requiresSession, {
                onSuccess: function(code, body) {
                    // Code = HTTP status code
                    // Body = Response from server
                    that.isSearching = false;
                    if (code != 200) {
                        // Something went wrong
                        var message = that.parseMessage(body);
                        if (message) {
                            that.errorMessage = message;
                        } else {
                            that.errorMessage = "Received a code " + code + " from the service";
                        }
                    } else {
                        try {
                            var result = JSON.parse(body);
                            that.processResponse(result);
                        } catch (err) {
                            console.log(err);
                            that.errorMessage = "Failed to parse response from the server";
                        }
                    }
                },
                onCancelled: function() {
                    // Fired if the request was cancelled
                    that.isSearching = false;
                },
                onFailure: function(error) {
                    // Fired if the request fails
                    that.isSearching = false;
                    that.errorMessage = error;
                }
            })

        },
    },
    mounted() {
        if (this.$route.query.order_by) {
            this.sort = this.$route.query.order_by;
        }

        if (this.$route.query.type) {
            this.recordType = this.$route.query.order_by;
        }

        if (this.$route.query.include_profiles) {
            this.profilesOn = this.$route.query.include_profiles;
        }

        if (this.$route.query.s) {
            this.searchTerm = this.$route.query.s;
            this.search();
        }
    }

}
</script>
